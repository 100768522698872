import {
    useEffect, useMemo, useRef, useContext,
} from 'react';
import _ from 'lodash';
import {CircularProgress} from '@mui/material';
import {FormContext} from 'components/Form/FormWrapper';
import {S3UploadProvider} from 'helper/bb-s3upload-provider';
import {v4 as uuidv4} from 'uuid'; // For unique IDs

/**
 * Takes a list of files and uploads it to s3
 * @param {FileList} files - files that should be uploaded
 * @param {object} options - all options passed to the uploadWrapper
 * @param {(files: FileList)=>ReturnType<import('helper/helper').S3UploadFunction>} options.upload - an upload function for s3
 * @param {boolean} options.multiple - indicates wether multiple files can be uploaded or a single file
 * @param {import('../form').ChangeHandler} options.changeHandler - changes the data of the formular
 * @param {string} options.attribute - key of the attribute to update in the form context
 * @param {string} options.fileReference - alternative form data key, where items are available
 * @param {import('../form').FormDataItem} options.referenceData - the form data items to the corresponding fileReference key
 */
const uploadWrapper = async (files, {
    upload, multiple, changeHandler, attribute, fileReference, referenceData,
}) => {
    const fileInformation = _.filter(await Promise.all(_.map(files, upload)), (i) => !_.isEmpty(i));

    // no files to pot into context
    if (!fileInformation.length) {
        return;
    }
    // only a single file can be put into context when multiple is false
    if (!multiple && fileInformation.length !== 1) {
        return;
    }

    if (multiple) {
        fileInformation.push(...(referenceData?.value ?? []));
    }
    changeHandler({
        attribute,
        displayValue: null,
        interacted: true,
        value: multiple
            // @ts-ignore
            ? JSON.stringify(fileInformation.map((p) => p.key))
            // @ts-ignore
            : fileInformation[0].key,
    });
    if (fileReference) {
        changeHandler({
            attribute: fileReference,
            displayValue: null,
            interacted: true,
            value: multiple
                ? fileInformation
                : fileInformation[0],
        });
    }
};

/**
 * A FormElement that wraps a button or other html element, and makes a file
 * selection dialog available when interacting with this element.
 * @param {object} props - props for the FormElement
 * @param {string} props.attribute - attribute to read from and write to
 * @param {string} props.fileReference - attribute to read/write file references to (cache)
 * @param {boolean} [props.multiple] - are multiple files allowed
 * @param {object} props.children - children of the component
 * @param {boolean} [props.disabled] - disables all controls
 * @param {boolean} [props.uploading] - indicates an upload
 * @param {import('helper/helper').S3UploadFunction} [props.upload] - S3 upload function
 * @returns {import('react').ReactElement} The FormElementFileUploadWrapper Component
 */
function FormElementFileUploadWrapper({
    attribute, fileReference, multiple, disabled, uploading, children, upload,
}) {
    const inputRef = useRef(null);

    const idSalt = useMemo(() => uuidv4(), []);

    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        get, formValidationSchemaAttributes, onBlurHandler, isLoading,
    } = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const elementData = useMemo(
        () => get(attribute),
        [attribute, get],
    );

    /**
     * Retrieving the value of the Reference from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const referenceData = useMemo(
        () => get(fileReference),
        [fileReference, get],
    );

    const formats = useMemo(() => formValidationSchemaAttributes?.[attribute]?.fileExtensions, [formValidationSchemaAttributes, attribute]);
    const innerUpload = S3UploadProvider();
    const actualUpload = upload ?? innerUpload.upload;
    const isUploading = uploading || innerUpload.isUploading || isLoading.load || isLoading.save;
    const isDisabled = disabled || isUploading;

    useEffect(() => {
        if (_.isNil(elementData?.value)) {
            inputRef.current.value = null;
        }
    }, [elementData?.value]);

    const uploadOptions = useMemo(() => ({
        upload: (files) => actualUpload(files, formats), multiple, changeHandler: onBlurHandler, attribute, fileReference, referenceData,
    }), [formats, actualUpload, multiple, onBlurHandler, attribute, fileReference, referenceData]);

    return (
        <>
            <label
                htmlFor={`contained-button-file${attribute}${idSalt}`}
                style={{cursor: 'pointer'}}
                data-test="FormElementFileUploadWrapper_label"
            >
                {!isUploading && children}
                {isUploading && (
                    <div style={{
                        display: 'grid',
                        placeItems: 'center',
                        width: '40px',
                        height: '40px',
                    }}
                    >
                        <CircularProgress />
                    </div>
                )}
            </label>
            <input
                data-test="FormElementFileUploadWrapper_input"
                id={`contained-button-file${attribute}${idSalt}`}
                accept={_.isEmpty(formats) ? 'image/*' : formats.map((e) => `.${e}`).join(',')}
                type="file"
                multiple={multiple}
                hidden
                ref={inputRef}
                onChange={(e) => { uploadWrapper(e.target.files, uploadOptions); }}
                disabled={isDisabled || disabled}
            />
        </>
    );
}

export {FormElementFileUploadWrapper, uploadWrapper};
