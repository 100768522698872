// PACKAGES
import _ from 'lodash';
// MATERIAL UI
import {Button, Slide} from '@mui/material';
import {Close} from '@mui/icons-material';
// SNACKBAR
import {SnackbarProvider, useSnackbar} from 'notistack';

/**
 * The SnackBarActions component.
 * @param {object} props - properties passed to the component.
 * @param {string} props.messageKey - key of the message to be displayed for the test.
 * @returns {import('react').ReactElement} The SnackBarActions component.
 */
function SnackBarActions({messageKey}) {
    const {closeSnackbar} = useSnackbar();
    return (
        <Button
            // split the key to get the messageKey to identify the button and trow away the id
            data-test={`${_.split(messageKey, '####')[0]}_close`}
            onClick={() => closeSnackbar(messageKey)}
        >
            <Close />
        </Button>
    );
}

const actionComp = (key) => <SnackBarActions messageKey={key} />;

/**
 * The wrapper for the MessageProvider component.
 * @param {object} props - properties passed to the component.
 * @param {object} props.children - children of the MessageProvider component.
 * @returns {import('react').ReactElement} MessageProvider component.
 */
function MessageProvider({children}) {
    return (
        <SnackbarProvider
            // preventDuplicate // when ids are chosen to prove a save operation was good, the duplication prevention is not applicable
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            TransitionComponent={Slide}
            action={actionComp}
            autoHideDuration={2000}
        >
            {children}

        </SnackbarProvider>
    );
}

export {MessageProvider};
