import {useNavigate} from 'react-router-dom';
import {getRoutePath} from 'helper/routes';
import {useFindRoute} from 'hooks/useFindRoute';

/**
 * The hook returns a navigation function
 * @module useRouteNavigation
 * @returns {(routeId: string, routeParams: Record<string, string>, routeState: any, queryParams?: import('routeinfo').QueryParams)=>void} Navigation function
 */
const useRouteNavigate = () => {
    const navigate = useNavigate();
    const findRoute = useFindRoute();
    return (routeId, routeParams, routeState, queryParams) => {
        const route = findRoute(routeId);
        if (!route) {
            throw new Error('Route not found. Try to navigate only after routes are set');
        }
        navigate(`/${getRoutePath(findRoute(routeId), routeParams, queryParams)}`, {state: routeState});
    };
};
export {useRouteNavigate};
