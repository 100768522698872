import {useContext, useMemo} from 'react';
import {FormContext} from 'components/Form/FormWrapper';

/**
 * A FormElement that inserts a Container to provide another element with the value of the selected source.
 * @param {object} props - props for the FormElementContainer
 * @param {string} [props.attribute] - source of the data (e.g. {attribute: 'vehicle'})
 * @param {Function} [props.valueMapping] - function to generate the value, will be used when no attribute is provided
 * @param {Function} [props.conditionalRender] - a function to decide whether to render or not
 * @returns {import('react').ReactElement} - the FormElementText component with the children inside.
 */
function FormElementText({
    attribute, valueMapping, conditionalRender,
}) {
    /**
     * Destructuring the FormContext
     */
    const {get} = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const elementData = useMemo(() => {
        if (attribute) {
            return get(attribute)?.value ?? '';
        }
        if (valueMapping) {
            return valueMapping(get) ?? '';
        }
        return '';
    }, [attribute, valueMapping, get]);

    if (conditionalRender && !conditionalRender(get)) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <></>;
    }
    return elementData;
}

export {FormElementText};
