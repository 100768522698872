import React, {useContext, useMemo} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';

/**
 * A FormElement that inserts a Container to provide another element with the value of the selected source.
 * @type {import('./formElements').FormElementContainer}
 */
function FormElementContainer({
    attribute, propsMapping, conditionalRender, children,
}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {changeHandler, get, isLoading} = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     */
    const elementData = useMemo(
        () => ({...(get(attribute) ?? {}), isLoading}),
        [attribute, isLoading, get],
    );

    const childProps = useMemo(() => ({...(propsMapping ? propsMapping(elementData, changeHandler, get) : {})}), [changeHandler, elementData, get, propsMapping]);
    const childrenWithProps = useMemo(() => React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, childProps);
        }
        return child;
    }), [children, childProps]);

    if (_.isFunction(conditionalRender)) {
        if (conditionalRender(!propsMapping ? elementData : childProps, get)) {
            return childrenWithProps;
        }
        return <div style={{display: 'none'}} />;
    }
    return childrenWithProps;
}

export {FormElementContainer};
