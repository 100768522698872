import {baseLinkSchemaOptions} from 'applications/baseLinkSchemaOptions';

/**
 * @typedef {import('./types').PermissionGroupTimeBuddy} PermissionGroupTimeBuddy
 * @typedef {import('applications/beyondbuddy/types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('beyond-types').Permission} Permission
 * @typedef {import('beyond-types').OperationName} OperationName
 * @typedef {import('beyond-types').UnmanagedObjectTimeBuddy} UnmanagedObjectTimeBuddy
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('beyond-types').ManagedObject} ManagedObject
 */

// eslint-disable-next-line
/** @typedef {`${OperationName}${UnmanagedObjectTimeBuddy}`} UnmanagedOperation */

/** @type {Record<PermissionGroupTimeBuddy, Permission[]>} */
const permissionGroupDefinition = {
    extendedRead: [
        'readWorkingTimeLog',
    ],
    createUpdateWorkingTimeLog: ['createWorkingTimeLog', 'updateWorkingTimeLog'],
};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    TimePeriod: baseLinkSchemaOptions(isIncoming),
    WorkingTimeModel: baseLinkSchemaOptions(isIncoming),
    WorkingTimeLogTemplate: baseLinkSchemaOptions(isIncoming),
    Workplace: baseLinkSchemaOptions(isIncoming),
});

/** @type {import('beyond-types').ManagedObject[]} */
const managedEntityKeys = ['TimePeriod', 'WorkingTimeLogTemplate', 'WorkingTimeModel', 'Workplace'];

/** @type {Record<UnmanagedOperation|Exclude<PermissionGroupTimeBuddy, PermissionGroupBeyondBuddy>, string>} */
const permissionLabels = {
    createWorkingTimeLog: 'Zeit erfassen',
    readWorkingTimeLog: 'Zeitbuchung lesen',
    updateWorkingTimeLog: 'Zeitbuchung bearbeiten',
    deleteWorkingTimeLog: 'Zeitbuchung löschen',
    createWorkingTimeModelAssignment: 'Zeitmodell zuweisen',
    readWorkingTimeModelAssignment: 'Zeitmodellzuweisungen lesen',
    updateWorkingTimeModelAssignment: 'Zeitmodellzuweisung bearbeiten',
    deleteWorkingTimeModelAssignment: 'Zeitmodellzuweisung entfernen',
    createWorkingTimeSchedule: 'Dienstplan erstellen',
    deleteWorkingTimeSchedule: 'Dienstplan löschen',
    readWorkingTimeSchedule: 'Dienstplan lesen',
    updateWorkingTimeSchedule: 'Dienstplan bearbeiten',
    createWorkingTimeAggregateCorrection: 'Zeitkonto korrigieren',
    deleteWorkingTimeAggregateCorrection: 'Zeitkonto Korrektur löschen',
    readWorkingTimeAggregateCorrection: 'Zeitkonto Korrekturen einsehen',
    updateWorkingTimeAggregateCorrection: 'Zeitkonto Korrekturen bearbeiten',
    createUpdateWorkingTimeLog: 'Zeit buchen',
    extendedRead: 'Erweitertes Lesen', // also defined in other applications like driveBuddy
};

export {
    permissionGroupDefinition as permissionGroupDefinitionTimeBuddy,
    // entityLabels as entityLabelsTimeBuddy,
    permissionLabels as permissionLabelsTimeBuddy,
    managedEntityKeys as managedEntityKeysTimeBuddy,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsTimeBuddy,
};
