import _ from 'lodash';

/**
 * Rewrites a attributes set into a valid graphql expression
 * @param {import('./helper').AttributeMap} attributes - attributes to rewrite
 * @returns {string} the constructed graphql expression
 */
const rewriteAttributesSection = (attributes) => _.chain(attributes)
    .map((subfields, variable) => (typeof (subfields) !== 'boolean'
        ? `${variable} ${rewriteAttributesSection(subfields)}`
        : variable))
    .join(',')
    .thru((value) => (value ? `{${value}}` : ''))
    .value();

/**
 * Creates a function that will create a query with only the necessary set of variables when invoked
 * @param {import('./helper').QuerySpec} querySpec - parameters to build the query with
 * @returns {(requestedVariables: string[], presentData: string[]) => string} function that generates a query string, once the data is decided
 */
const buildQuery = ({
    queryLabel, queryName, possibleInputVariables, possibleAttributes,
}) => (
    (requestedAttributes, presentData) => {
        const inputVarString = _.chain(possibleInputVariables)
            .pick(presentData)
            .map((typeName, variable) => `$${variable}: ${typeName}`)
            .join(',')
            .thru((value) => (value ? `(${value})` : ''))
            .value();
        const variableMapString = _.chain(possibleInputVariables)
            .pick(presentData)
            .keys()
            .map((varname) => `${varname}: $${varname}`)
            .join(',')
            .thru((value) => (value ? `(${value})` : ''))
            .value();
        const attributeString = rewriteAttributesSection(_.pick(possibleAttributes, requestedAttributes));
        return `query ${queryLabel} ${inputVarString} { ${queryName} ${variableMapString} ${attributeString}}`;
    }
);

export {buildQuery};
