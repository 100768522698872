import {useMemo} from 'react';
import _ from 'lodash';
import {Box, Typography} from '@mui/material';
import {useGlobalState} from 'hooks/useGlobalState';

import classes from 'assets/theme/layout/Menu/profileMenu.module.scss';
import {Edit} from '@mui/icons-material';
import {MenuLink} from 'components/MenuLink';

const [height, width] = [240, 240];

/**
 * The wrapper for the ProfileMenu component.
 * @param {object} props - properties passed to the component
 * @param {Array<import('routeinfo').ApplicationMenuInfo>} props.menus - all application menus
 * @returns {import('react').ReactElement} The ProfileMenu component.
 */
function ProfileInfo({menus}) {
    // console.log(menus);
    const profileMenuLink = _.chain(menus)
        .find((app) => app.applicationId === 'beyondbuddy')
        .get('modules')
        .find((mod) => mod.moduleId === 'beyondbuddy_settings_general')
        .get('menus')
        .find((men) => men.id === 'beyondbuddy_settings_general_users_menu')
        .get('menus')
        .find((m) => m.id === 'beyondbuddy_settings_general_user_profile_menu')
        .get('basePath')
        .value();
    // getting the global state function for retrieving a state value
    const {getGlobal} = useGlobalState();
    // try to get the userId, setting it null in case the user information is not available (async!)
    const {
        contactFirstName,
        contactLastName,
        image,
    } = getGlobal('user') ?? {};

    // eslint-disable-next-line function-paren-newline
    const imageStyle = useMemo(
        /** @type {{height?: number, width?: number, objectPosition?: string}} */
        () => {
            /** @type {import('beyond-types').FileInformationEntity} */
            const refVal = image;
            const position = refVal?.options?.image?.position;
            if (position) {
                const x = (position.x / position.width) * -width;
                const y = (position.y / position.height) * -height;
                return {
                    height: height / position.height,
                    width: width / position.width,
                    objectPosition: `${x}px ${y}px`,
                };
            }
            return {
                width,
                height,
                objectPosition: '0px 0px',
            };
        }, [image]);

    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.2rem',
                borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            }}
            data-test="profile_information"
        >
            {image?.url
            && (
                <Box
                    sx={{
                        minWidth: '100%',
                        display: {xs: 'none', md: 'block'},
                    }}
                    className={classes.imageContainer}
                    data-test="user_profile_image"
                >
                    <Box
                        component="img"
                        alt="Profile"
                        src={image?.url}
                        style={imageStyle}
                    />
                </Box>
            )}
            <Box
                className={classes.profileButton}
                data-test="user_name_button"
                sx={{
                    ':hover *': {color: 'info.main'},
                }}
            >
                <MenuLink to={profileMenuLink}>
                    <>
                        <Typography
                            data-test="user_name"
                            style={{color: 'info.main'}}
                        >
                            {`${_.chain([contactFirstName, contactLastName]).compact().join(' ').value()}`}
                        </Typography>
                        <Typography style={{color: 'info.main'}}><Edit /></Typography>
                    </>
                </MenuLink>
            </Box>
            {/* <Box style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2rem',
            }}
            >
                <Typography
                    className={classes.name}
                    data-test="Username"
                >
                    {`${_.chain([contactFirstName, contactLastName]).compact().join(' ').value()}`}
                </Typography>
                <Link
            </Box> */}
        </Box>
    );
}

export {ProfileInfo};
