import {useCallback, useContext, useMemo} from 'react';
import {FormContext} from 'components/Form/FormWrapper';
import _ from 'lodash';
import {TextField, Skeleton} from '@mui/material';

/**
 * It renders a TextField component from Material UI and handles the value and error state.
 * @type {import('./formElements').FormElementTextField}
 */
function FormElementTextField({
    attribute, type, onBlur, min, max, readOnly, ...rest
}) {
    /**
     * Destructuring the FormContext and assigning the values.
     */
    const {
        isLoading, get, onBlurHandler, changeHandler, isReadonly,
    } = useContext(FormContext);

    /**
     * Retrieving the value of the FormElement from the context
     * In case of no value, it returns the corresponding value for "no value" (e.g. null)
     * TODO: Negative numbers are not allowed in the input field. This should be fixed.
     */
    const elementData = useMemo(() => get(attribute), [attribute, get]);

    const onChangeWrapper = useCallback((event) => {
        let fieldValue = event.target.value;
        if (type) {
            fieldValue = type === 'int'
                ? parseInt(fieldValue, 10)
                : parseFloat(fieldValue);
            if (fieldValue >= 4294967294 || (min && fieldValue < min) || (max && fieldValue > max)) {
                return;
            }
            if (type && (_.isNaN(fieldValue))) {
                fieldValue = null;
            }
        }

        changeHandler({
            attribute,
            value: fieldValue === '' ? null : fieldValue,
            displayValue: fieldValue,
            error: elementData?.error,
            interacted: true,
        });
    }, [attribute, changeHandler, elementData?.error, max, min, type]);

    /**
     * Displays a loading animation if the context is still loading.
     */
    if (isLoading?.load || isLoading?.save) {
        return <Skeleton variant="rectangular" animation="wave" width="100%" height={rest?.rows ? `${rest.rows * 20 + 16.5 * 2}px` : '53.13px'} />;
    }

    return (
        <TextField
            data-test={`FormElementText${!rest.rows ? 'Field' : 'Area'}_${attribute}`}
            value={elementData?.value ?? ''}
            id={attribute}
            type={['int', 'float'].includes(type) ? 'number' : type}
            onChange={onChangeWrapper}
            error={!_.isUndefined(elementData?.error)}
            helperText={elementData?.error?.value}
            aria-readonly={(isReadonly || readOnly)}
            // Overwriting readonly with disabled in case that no value is set
            disabled={!elementData?.value && (isReadonly || readOnly)}
            onBlur={(e) => {
                if (_.isFunction(onBlur)) {
                    onBlur(e);
                }
                onBlurHandler({
                    attribute,
                    value: elementData?.value,
                    displayValue: elementData?.displayValue,
                    error: elementData?.error,
                });
            }}
            multiline={!!rest?.rows}
            InputProps={{readOnly: (isReadonly || readOnly)}}
            {...rest}
        />
    );
}

export {FormElementTextField};
