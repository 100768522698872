import _ from 'lodash';
import {Box} from '@mui/material';

import classes from 'assets/theme/layout/Menu/applicationMenu.module.scss';
import {useRouteInformation} from 'applications/configs';
import {MenuLink} from 'components/MenuLink';

/**
 * The wrapper for the ApplicationMenu component.
 * @param {object} props - properties passed to the component
 * @param {Array<import('routeinfo').ApplicationMenuInfo>} props.menus - all application menus
 * @returns {import('react').ReactElement} - ApplicationMenu component
 */
function ApplicationMenu({menus}) {
    const {isActive} = useRouteInformation(menus);

    return (
        <Box className={classes.container} data-test="applicationmenu">
            {_.map(menus, ({
                menu: {
                    id, icon, label, path,
                },
            }) => (
                <Box
                    key={id}
                    className={classes.menuButtonContainer}
                    sx={{
                        '*': {
                            color: isActive(id) ? 'info.main' : 'primary.main',
                        },
                        ':hover *': {color: 'info.main'},
                    }}
                >
                    <MenuLink
                        to={path}
                        data-test={`${id}_button`}
                    >
                        <>
                            {icon}
                            {label}
                        </>
                    </MenuLink>
                </Box>
            ))}
        </Box>
    );
}

export {ApplicationMenu};
