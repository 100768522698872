import {useCallback} from 'react';
// PACKAGES
import _ from 'lodash';
// HOOKS
import {useGlobalState} from 'hooks/useGlobalState';

/**
 * A hook to the variables from the global state.
 * @type {()=>{getVariables: import('./types').GetVariablesFunction}}
 */

const useVariables = () => {
    // getting the global state function for retrieving a state value
    const {getGlobal} = useGlobalState();

    /**
     * Filters out variables with falsy values from an object.
     * @param {object} source - The object to filter.
     * @returns {object} A new object containing only the properties with truthy values.
     */
    const filterTruthyValues = (source) => _.pickBy(source, Boolean);

    return {
        /** @type {import('./types').GetVariablesFunction} */
        getVariables: useCallback((containerVariable) => {
            if (!containerVariable || !_.isObject(containerVariable) || _.isEmpty(containerVariable)) {
                return {};
            }
            return {
                // Process global variables
                ...filterTruthyValues(_.mapValues(
                    containerVariable?.global,
                    (g) => getGlobal(g),
                ) ?? {}),
                // Process direct variables
                ...filterTruthyValues(
                    containerVariable?.direct,
                ),
            };
        }, [getGlobal]),
    };
};
export {useVariables};
