import {baseLinkSchemaOptions} from 'applications/baseLinkSchemaOptions';

/**
 * @typedef {import('./types').PermissionGroupDriveBuddy} PermissionGroupDriveBuddy
 * @typedef {import('applications/beyondbuddy/types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('beyond-types').Permission} Permission
 * @typedef {import('beyond-types').OperationName} OperationName
 * @typedef {import('beyond-types').UnmanagedObjectDriveBuddy} UnmanagedObjectDriveBuddy
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('beyond-types').ManagedObject} ManagedObject
 */

// eslint-disable-next-line
/** @typedef {`${OperationName}${UnmanagedObjectDriveBuddy}`} UnmanagedOperation */

/** @type {Record<PermissionGroupDriveBuddy, Permission[]>} */
const permissionGroupDefinition = {
    extendedRead: [
        'readDrivingRecord', 'readVehicleProtocolEntry',
    ],
    useVehicleGroup: ['read', 'createDrivingRecord', 'readVehicleProtocolEntry', 'createVehicleProtocolEntry', 'updateVehicleProtocolEntry'],
    administrateVehicleGroup:
        [
            'read', 'update', 'delete',
            'createDrivingRecord', 'readDrivingRecord', 'updateDrivingRecord', 'deleteDrivingRecord',
            'createVehicleProtocolEntry', 'readVehicleProtocolEntry', 'updateVehicleProtocolEntry', 'deleteVehicleProtocolEntry',
            'readPermission', 'updatePermission',
        ],
};

/** @type {import('beyond-types').ManagedObject[]} */
const managedEntityKeys = ['Vehicle'];

/** @type {Record<UnmanagedOperation|Exclude<PermissionGroupDriveBuddy, PermissionGroupBeyondBuddy>, string>} */
const permissionLabels = {
    administrateVehicleGroup: 'Verwalten',
    useVehicleGroup: 'Verwenden',
    createDrivingRecord: 'Fahrten anlegen',
    createVehicleProtocolEntry: 'Protokolleinträge anlegen',
    deleteDrivingRecord: 'Fahrten löschen',
    deleteVehicleProtocolEntry: 'Protokolleinträge löschen',
    readDrivingRecord: 'Fahrten lesen',
    readVehicleProtocolEntry: 'Protokolleinträge lesen',
    updateDrivingRecord: 'Fahrten bearbeiten',
    updateVehicleProtocolEntry: 'Protokolleinträge bearbeiten',
    extendedRead: 'Erweitertes Lesen', // also defined in other applications like driveBuddy
};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    Vehicle: baseLinkSchemaOptions(isIncoming),
});

export {
    permissionGroupDefinition as permissionGroupDefinitionDriveBuddy,
    // entityLabels as entityLabelsDriveBuddy,
    permissionLabels as permissionLabelsDriveBuddy,
    managedEntityKeys as managedEntityKeysDriveBuddy,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsDriveBuddy,
};
