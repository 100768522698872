import _ from 'lodash';

/**
 * @param {import("routeinfo").RoutePathInfo} route - information about the route
 * @param {Record<string, string>} [params] - replacement params for dynamic route paths
 * @param {import('routeinfo').QueryParams} [queryParams] - query and hash parameters for the page path
 * @returns {string} the enriched route path
 */
function getRoutePath(route, params, queryParams = {}) {
    if (!route) {
        return undefined;
    }
    let path = route?.path;
    const {
        hashFragment,
        query,
    } = queryParams;
    _.forEach(params, (v, k) => {
        path = path.replace(`:${k}`, v);
    });
    if (query) {
        // @ts-ignore URLSearchParamsInit is valid to create URLSearchParams
        path += `?${new URLSearchParams(query)}`;
    }
    if (hashFragment) {
        path += `#${hashFragment}`;
    }
    return path;
}

export {
    getRoutePath,
};
