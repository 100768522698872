import cdkOut from 'cdk-exports.json';

const {
    AWSREGION,
    APPSYNCAPIENDPOINT,
    APPSYNCAPIKEY,
} = cdkOut[Object.keys(cdkOut)[0]];

/** @type {import('aws-amplify').ResourcesConfig} */
const awsConfigAPIKEY = {
    API: {
        GraphQL: {
            endpoint: APPSYNCAPIENDPOINT,
            region: AWSREGION,
            apiKey: APPSYNCAPIKEY,
            defaultAuthMode: 'apiKey',
        },
    },
};

/** @type {import('aws-amplify').ResourcesConfig} */
const awsConfigLambda = {
    Auth: {
        // @ts-ignore
        Cognito: {
            userAttributes: {email: {required: true}},
            signUpVerificationMethod: 'code',
            passwordFormat: {
                minLength: 9,
            },
        },
    },
    API: {
        GraphQL: {
            endpoint: APPSYNCAPIENDPOINT,
            region: AWSREGION,
            apiKey: APPSYNCAPIKEY,
            defaultAuthMode: 'lambda',
        },
    },
};

export {awsConfigAPIKEY, awsConfigLambda};
