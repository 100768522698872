import {
    useCallback, useContext,
} from 'react';
// PACKAGES
import _ from 'lodash';
// CONTEXT
import {Context} from 'context/AppContext';

/** @type {()=> import('hooks/types').GlobalStateFunctions} */
const useGlobalState = () => {
    const ctx = useContext(Context);
    if (_.isNil(ctx)) {
        throw new Error('Global Context is missing. You need to add the Context to your App!');
    }

    // The AppContext.
    const {data, setData} = ctx;

    /** @type {import('./types').GlobalStateFunctions['setGlobal']} */
    const setGlobal = useCallback((
        key,
        value,
        persistent,
    ) => {
        if (persistent) {
            localStorage.setItem(key, JSON.stringify(value));
        } else {
            setData((
                /** @type {object} */
                curr,
            ) => {
                if (_.isEqual(curr[key], value)) {
                    return curr;
                }
                return {
                    ...curr,
                    [key]: value,
                };
            });
        }
    }, [setData]);

    /** @type {import('./types').GlobalStateFunctions['getGlobal']} */
    const getGlobal = useCallback(
        // console.log(`requesting ${key} from the state`);
        (
            /** @type {string} - key of the value */
            key,
        ) => {
            const stateValue = _.get(data, key);
            if (_.isNil(stateValue)) {
                const localStorageValue = localStorage.getItem(key);
                if (!_.isNil(localStorageValue)) {
                    return JSON.parse(localStorageValue);
                }
                return undefined;
            }
            return stateValue;
        },
        [data],
    );

    /** @type {import('./types').GlobalStateFunctions['deleteGlobal']} */
    const deleteGlobal = useCallback((
        /** @type {string} */
        key,
    ) => {
        // delete the current variable
        setData((curr) => (key in curr
            ? _.omitBy(curr, (v, k) => k === key)
            : curr));
        localStorage.removeItem(key);
    }, [setData]);

    /** @type {import('./types').GlobalStateFunctions['deleteGlobalAll']} */
    const deleteGlobalAll = useCallback(() => {
        _.forEach(data, (v, k) => deleteGlobal(k));
    }, [deleteGlobal, data]);

    /**
     * Checks if a form has changes
     * When a context is provided, it checks if the form with that context has changes
     * @function getFormHasChangesState
     * @memberof module:useGlobalState
     * @returns {Array<string>|boolean}
     */
    const getFormHasChangesState = useCallback((context) => {
        /** @type {Record<string, Array<string>>} */
        const formHasUnsavedChanges = getGlobal('formHasUnsavedChanges');
        if (!formHasUnsavedChanges) {
            return false;
        }
        const item = context && _.find(formHasUnsavedChanges, (k, e) => _.includes(e, context));

        return context
            ? item ?? false
            : true;
    }, [getGlobal]);

    return {
        getGlobal,
        setGlobal,
        deleteGlobal,
        deleteGlobalAll,
        getFormHasChangesState,
    };
};
export {useGlobalState};
