import {useContext} from 'react';
import {
    Chip, Collapse, Grid2, Tooltip,
} from '@mui/material';
import {ItemDataContext} from 'components/Form/ItemData';

/**
 * Form element that displays a set of badges according to the values provided
 * by the item data context
 * @param {object} props - options that will be checked and shown
 * @param {boolean} [props.showDraft] - indicates that draft status should be checked
 * @param {boolean} [props.showReadonly] - indicates that readonly status should be checked
 * @param {boolean} [props.isDraft] - indicates that the status is draft
 * @param {boolean} [props.isReadonly] - indicates that the status is readonly
 * @param {import('react').ReactNode} [props.children] - other chips that should be rendered
 * @param {import('@mui/material').Grid2Props} [props.gridProps] - optional props for the grid item, wrapping the chip
 * @returns {import('react').ReactElement} element to render
 */
export function FormElementInfoChips({
    showDraft, showReadonly, isDraft, isReadonly, children, gridProps,
}) {
    const {data} = useContext(ItemDataContext);
    const isDraftCalc = isDraft || (showDraft && (data?.draft || false));
    const isReadonlyCalc = isReadonly || (showReadonly && (data?.grants && !data.grants.updatable));

    return (
        <Grid2 size={{xs: 12}} display="flex" justifyContent="flex-end" gap="2em" {...gridProps}>
            <Collapse in={isReadonlyCalc} hidden={!showReadonly}>
                <Tooltip title="Sie haben ausschließlich lesenden Zugriff!">
                    <Chip data-test="FormElementInfoChip" label="ReadOnly" />
                </Tooltip>
            </Collapse>
            <Collapse in={isDraftCalc} hidden={!showDraft}>
                <Chip data-test="FormElementInfoChip" label="Entwurf" />
            </Collapse>
            {children}
        </Grid2>
    );
}
