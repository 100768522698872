/**
 * @typedef {import('./types').PermissionGroupPeaceBuddy} PermissionGroupPeaceBuddy
 * @typedef {import('applications/beyondbuddy/types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('beyond-types').Permission} Permission
 * @typedef {import('beyond-types').OperationName} OperationName
 * @typedef {import('beyond-types').UnmanagedObjectPeaceBuddy} UnmanagedObjectPeaceBuddy
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('beyond-types').ManagedObject} ManagedObject
 */
// eslint-disable-next-line
/** @typedef {`${OperationName}${UnmanagedObjectPeaceBuddy}`} UnmanagedOperation */

/** @type {Record<PermissionGroupPeaceBuddy, Permission[]>} */
const permissionGroupDefinition = {
    extendedReadPeaceBuddy: [
        'readGraveRecord',
    ],
    administrateDeceasedPersonGroup:
    [
        'read', 'update', 'delete',
        'readPermission', 'updatePermission',
        'readAttributes', 'updateAttributes',
        'createGraveRecord', 'readGraveRecord', 'updateGraveRecord', 'deleteGraveRecord',
        'readProtocolEntry', 'createProtocolEntry', 'updateProtocolEntry', 'deleteProtocolEntry',
    ],
    administrateGraveGroup:
    [
        'read', 'update', 'delete',
        'readPermission', 'updatePermission',
        'readAttributes', 'updateAttributes',
        'createGraveRecord', 'readGraveRecord', 'updateGraveRecord', 'deleteGraveRecord',
        'readProtocolEntry', 'createProtocolEntry', 'updateProtocolEntry', 'deleteProtocolEntry',
    ],

};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = () => ({});

/** @type {import('beyond-types').ManagedObject[]} */
const managedEntityKeys = ['Cemetery', 'Grave', 'DeceasedPerson'];

/** @type {Record<UnmanagedOperation|Exclude<PermissionGroupPeaceBuddy, PermissionGroupBeyondBuddy>, string>} */
const permissionLabels = {
    administrateDeceasedPersonGroup: 'Verwalten',
    administrateGraveGroup: 'Verwalten',
    extendedReadPeaceBuddy: 'Erweitertes Lesen', // also defined in other applications like driveBuddy
    readGraveRecord: 'Grab Eintrag lesen',
    createGraveRecord: 'Grab Eintrag anlegen',
    updateGraveRecord: 'Grab Eintrag bearbeiten',
    deleteGraveRecord: 'Grab Eintrag löschen',
};

export {
    permissionGroupDefinition as permissionGroupDefinitionPeaceBuddy,
    // entityLabels as entityLabelsPeaceBuddy,
    permissionLabels as permissionLabelsPeaceBuddy,
    managedEntityKeys as managedEntityKeysPeaceBuddy,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsPeaceBuddy,
};
