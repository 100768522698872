import {useGlobalState} from 'hooks/useGlobalState';

/**
 * Returns the users set or preferred color mode (light/dark)
 * @returns {import('@mui/material').PaletteMode} - the users color mode
 */
function useUsersColorMode() {
    const {getGlobal} = useGlobalState();
    const userObj = getGlobal('user');

    if (userObj?.colorMode && (userObj.colorMode === 'dark' || userObj.colorMode === 'light')) {
        return userObj.colorMode;
    }

    return window.matchMedia('(prefers-color-scheme: dark)')
        .matches ? 'dark' : 'light';
}

export {useUsersColorMode};
