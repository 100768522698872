// React and core libraries
import {
    useState,
    useMemo,
    useCallback,
    useEffect,
    useRef,
    useContext,
    createContext,
} from 'react';

// RxJS imports
import {
    EMPTY,
    NEVER,
    Observable,
    catchError,
    concatAll,
    defer,
    delay,
    filter,
    firstValueFrom,
    forkJoin,
    map,
    of,
    finalize,
    switchMap,
    throwError,
    retry,
} from 'rxjs';

// Third-party utilities
import _ from 'lodash';
import _set from 'lodash/fp/set';

// Custom hooks
import {useVariables} from 'hooks/useVariables';
import {useMessage} from 'hooks/useMessage';
import {useLogMessage} from 'hooks/useLogMessage';
import {CancelException} from 'hooks/useCancellablePromise';

// Helper utilities
import {maskVariables} from 'helper/mask-variables';
import {AWSAppSyncProvider, ConnectionClosedException} from 'helper/bb-graphql-provider';
import {pageVisible$} from 'helper/visibility';

// Messages and exceptions
import {Exceptions} from 'messages/Exceptions';
import {Messages} from 'messages/Messages';

// Application components
import {NotFound} from 'applications/pages/NotFound';

/**
 * @type {import('react').Context<import('components/Form/form').TItemDataContext>}
 */
const ItemDataContext = createContext(Object());

/**
 * Recursively loads provided configs
 * @param {import('components/Form/form').ItemLoadConfig} config Load configuration
 * @param {function(string,object): Observable<object>} queryFn function to query with
 * @param {function(object): object} getVariables variable resolver
 * @param {object} parentObject result item of the parent query
 * @returns {Observable<object>} the result of the load operation
 */
const chainPostProcess = (config, queryFn, getVariables, parentObject) => {
    const {variables, missingObligatoryVariables} = maskVariables({
        ...getVariables(config.variables),
        ..._.chain(config.variables?.parent)
            .mapValues((attribute) => _.get(parentObject, attribute))
            .pickBy()
            .value(),
    }, config.mask);

    if (!_.isEmpty(missingObligatoryVariables)) {
        // eslint-disable-next-line no-console
        console.error('Missing obligatory variables in ', config);
        // eslint-disable-next-line no-console
        console.table(missingObligatoryVariables);
        // throw new Error(Exceptions.MISSING_ATTRIBUTES_ERROR, {missingAttributes: missingObligatoryVariables});
        throw new Error(Exceptions.MISSING_ATTRIBUTES_ERROR.message.de);
    }
    const id = map(_.identity);
    return queryFn(config.query, variables).pipe(
        retry({count: 3, delay: 30}), // Retries up to 3 times, with a 30 ms delay
        !_.isEmpty(config.postLoads)
            ? switchMap((result) => {
                const loadResults$ = _.mapValues(
                    config.postLoads,
                    (value) => chainPostProcess(value, queryFn, getVariables, result),
                );
                return forkJoin(loadResults$).pipe(
                    map((loadResults) => ({
                        ...result,
                        ...loadResults,
                    })),
                );
            }) : id,
        _.isFunction(config.postProcess)
            ? switchMap(async (loadResult) => config.postProcess(loadResult)) : id,
        catchError((error) => {
            if (error instanceof CancelException) {
                return EMPTY;
            }
            if (_.get(error, 'errors.0.errorType') === 'NotFoundError') {
                return throwError(() => Exceptions.ITEM_NOT_FOUND);
            }
            // eslint-disable-next-line no-console
            console.error(error);
            return throwError(() => Exceptions.API_LOAD_ERROR);
        }),
    );
};

/**
 * ## ItemData
 *
 * Component to instances of DataContext
 * @param {import('components/Form/form').ItemDataProps} props the Props of the component
 * @returns {import('react').ReactElement} Data element that provides data to children.
 */
function ItemData({
    children, loadConfig, extraDataLoadConfig, syncConfig, saveConfig, initialData, onChange, errorMapping,
}) {
    const [notFound, setNotFound] = useState(false);
    const {getVariables} = useVariables();
    const {
        getItem, editItem, call,
    } = AWSAppSyncProvider();
    const {enqueueMessage} = useMessage();
    const {logMessage} = useLogMessage();

    const [data, setData] = useState(Object());
    const [isSaving, setSaving] = useState(false);
    const [isSynced, setIsSynced] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const {current: initialValues} = useRef(Object());

    const {data: parentItemData} = useContext(ItemDataContext);

    const processError = useCallback((error, messageKey, maskedVariables) => {
        const message = _.find(errorMapping, (e) => _.includes(error.message, e.technical));

        if (message) {
            // eslint-disable-next-line no-console
            console.error(error.message);
            return enqueueMessage(messageKey, {
                message,
                options: {
                    variant: 'error',
                },
            });
        }
        if (_.includes(error.message, 'You are not authorized due')) {
            return enqueueMessage(messageKey, Exceptions.API_AUTHORIZATION_PERMISSION_MISSING);
        }
        if (_.includes(error.message, 'The provided item is not unique!')) {
            return enqueueMessage(messageKey, Exceptions.API_ITEM_IS_NOT_UNIQUE);
        }
        if (_.includes(error.message, 'Validation failed!')) {
            return enqueueMessage(messageKey, Exceptions.API_VALIDATION_FAILED);
        }
        return enqueueMessage(messageKey, saveConfig?.errorMessage ?? Exceptions.API_SAVE_ERROR, {error, item: maskedVariables});
    }, [errorMapping, enqueueMessage, saveConfig?.errorMessage]);

    /**
     * @type {function(import('components/Form/form').ItemDataConfigBase): Promise<boolean>}
     */
    const loadExtraData = useCallback(({messageKey, variables}) => {
        if (_.isEmpty(extraDataLoadConfig)) {
            return Promise.resolve(true);
        }
        const loadConfigWithVariables = {
            ...extraDataLoadConfig,
            variables: {
                ...extraDataLoadConfig.variables,
                direct: {
                    ...extraDataLoadConfig.variables.direct,
                    ...variables,
                },
            },
        };
        const queryFn = (query, vars) => defer(() => getItem(query, vars));
        setLoading(true);
        const result$ = chainPostProcess(loadConfigWithVariables, queryFn, getVariables, {}).pipe(
            retry({count: 3, delay: 30}), // Retries up to 3 times, with a 30 ms delay
            catchError((error) => {
                if (error === Exceptions.ITEM_NOT_FOUND) {
                    setNotFound(true);
                } else if (_.includes(Exceptions, error)) {
                    enqueueMessage(messageKey, error);
                }
                if (error instanceof ConnectionClosedException) {
                    // eslint-disable-next-line no-console
                    console.error('Problem during load of extra data', error);
                    enqueueMessage(messageKey, Exceptions.API_CONNECTION_ERROR, {error});
                    logMessage('PageError', error, true);
                } else if (!(error instanceof CancelException)) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    logMessage('PageError', error, true);
                }
                return of(false);
            }),
            map((result) => {
                if (_.isEmpty(result)) {
                    setNotFound(true);
                    throw (Exceptions.API_LOAD_ERROR);
                }
                // console.log('newData', result);
                setData((current) => ({...current, itemDataExtraData: result}));
                return true;
            }),
        );
        return firstValueFrom(result$);
    }, [extraDataLoadConfig, getVariables, getItem, enqueueMessage, logMessage]);

    /** @type {import('components/Form/form').TItemDataContext['load']} */
    const load = useCallback(({messageKey, variables}) => {
        if (_.isEmpty(loadConfig)) {
            return Promise.resolve(true);
        }
        const loadConfigWithVariables = {
            ...loadConfig,
            variables: {
                ...loadConfig.variables,
                direct: {
                    ...loadConfig.variables.direct,
                    ...variables,
                },
            },
        };
        const queryFn = (query, vars) => defer(() => getItem(query, vars));
        setLoading(true);
        const result$ = chainPostProcess(loadConfigWithVariables, queryFn, getVariables, {}).pipe(
            catchError((error) => {
                if (error === Exceptions.ITEM_NOT_FOUND) {
                    setNotFound(true);
                } else if (_.includes(Exceptions, error)) {
                    enqueueMessage(messageKey, error);
                }
                if (error instanceof ConnectionClosedException) {
                    // eslint-disable-next-line no-console
                    console.error('Problem during load', error);
                    enqueueMessage(messageKey, Exceptions.API_CONNECTION_ERROR, {error});
                    logMessage('PageError', error, true);
                } else if (!(error instanceof CancelException)) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    logMessage('PageError', error, true);
                }
                return of(false);
            }),
            map((result) => {
                if (_.isEmpty(result)) {
                    setNotFound(true);
                    throw (Exceptions.API_LOAD_ERROR);
                }
                const newData = {...data, ...result, parentItemData};
                // console.log('newData', newData);
                setData(newData);
                Object.assign(initialValues, _.cloneDeep(newData)); // is required
                onChange?.(newData);
                loadExtraData({messageKey, variables});
                return true;
            }),
        );
        return firstValueFrom(result$)
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                // könnte EmptyErrorImpl error sein - könnte man abfangen (kommt davon wenn man schnell wechselt und die Requests nicht fertig sind)
            })
            .finally(() => {
                setLoading(false);
            });
    }, [loadConfig, data, parentItemData, initialValues,
        getVariables, getItem, enqueueMessage, logMessage, onChange, loadExtraData]);

    /** @type {import('components/Form/form').TItemDataContext['sync']} */
    const sync = useCallback(({messageKey, variables}) => {
        if (!syncConfig) {
            return NEVER;
        }
        const queryFn = (query, vars) => defer(async () => {
            const getter = await call(query, vars);
            if ('subscribe' in getter && typeof getter.subscribe === 'function') {
                return new Observable((subscriber) => getter
                    .subscribe(subscriber)).pipe(
                    map((result) => Object.values(result.value.data)[0]),
                    // Filtering out null values, in case non sufficient mutations were made
                    filter(_.identity),
                );
            }
            return of(getter);
        }).pipe(concatAll());
        const syncConfigWithVariables = {
            ...syncConfig,
            variables: {
                ...syncConfig?.variables,
                direct: {
                    ...syncConfig?.variables.direct,
                    ...variables,
                },
            },
        };

        // Only keeping the subscription up if the page is visible
        return pageVisible$.pipe(
            switchMap((visible) => {
                if (!visible) {
                    return NEVER;
                }
                setIsSynced(true); // Handle subscribe logic
                return chainPostProcess(syncConfigWithVariables, queryFn, getVariables, {}).pipe(
                    finalize(() => setIsSynced(false)), // Handle unsubscribe logic
                    catchError((error, caught) => {
                        if (_.includes(Exceptions, error)) {
                            enqueueMessage(messageKey, error);
                        }
                        if (error instanceof ConnectionClosedException) {
                            // eslint-disable-next-line no-console
                            console.error('Problem during sync', error);
                            enqueueMessage(messageKey, Exceptions.API_CONNECTION_ERROR, {error});
                            logMessage('PageError', error, true);
                        } else if (!(error instanceof CancelException)) {
                            // eslint-disable-next-line no-console
                            console.error(error);
                            logMessage('PageError', error, true);
                        }
                        return caught.pipe(
                            delay(3000),
                        );
                    }),
                    map((result) => {
                        if (_.isEmpty(result)) {
                            setNotFound(true);
                            throw Exceptions.API_LOAD_ERROR;
                        }
                        const newData = result;
                        setData(newData);
                        Object.assign(initialValues, _.cloneDeep(newData));
                        onChange?.(newData);
                        return newData;
                    }),
                );
            }),
        );
    }, [syncConfig, call, getVariables, enqueueMessage, logMessage, initialValues, onChange]);

    /** @type {import('components/Form/form').TItemDataContext['save']} */
    const save = useCallback(async ({messageKey, onSaveCallback, variables}) => {
        if (_.isEmpty(saveConfig)) {
            // eslint-disable-next-line no-console
            console.warn('Could not save. No save config provided');
            return false;
        }
        const processedData = saveConfig.preProcess
            ? await saveConfig.preProcess(data)
            : data;
            // maybe some custom preProcess validation failed
        if (processedData === false) {
            return false;
        }
        const {variables: maskedVariables, missingObligatoryVariables} = maskVariables({
            ...getVariables(saveConfig.variables),
            ...processedData,
            ...variables,
        }, saveConfig.mask);
        // not possible - missing obligatory!
        if (!_.isEmpty(missingObligatoryVariables)) {
            // eslint-disable-next-line no-console
            console.error('Could not save. Missing variables');
            // eslint-disable-next-line no-console
            console.table(missingObligatoryVariables);
            enqueueMessage(messageKey, Exceptions.MISSING_ATTRIBUTES_ERROR);
            return false;
        }
        try {
            setSaving(true);
            const result = await editItem(saveConfig?.mutation, maskedVariables);
            const processedResult = (saveConfig.postProcess ?? _.identity)(result);
            const newData = {...data, ...processedResult, parentItemData};
            setData(newData);
            Object.assign(initialValues, _.cloneDeep(newData));
            if (_.isFunction(onSaveCallback)) {
                try {
                    onSaveCallback(processedResult);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }
            }
            onChange?.(newData);
            enqueueMessage(messageKey, saveConfig?.successMessage ?? Messages.API_SAVE_SUCCESSFUL);
            return true;
        } catch (e) {
            if (e instanceof ConnectionClosedException) {
                // eslint-disable-next-line no-console
                console.error('Problem during load', e);
            }
            if (e instanceof CancelException) {
                return false;
            }
            logMessage('PageError', e, true);

            // check the error message to determine where it comes from
            if (e?.errors) {
                e.errors?.forEach((error) => processError(error, messageKey, maskVariables));
                if (_.isFunction(onSaveCallback)) {
                    onSaveCallback(e);
                }
            }

            return false;
        } finally {
            setSaving(false);
        }
    }, [saveConfig, data, getVariables, enqueueMessage, editItem, parentItemData, initialValues, onChange, logMessage, processError]);

    /** @type {import('components/Form/form').TItemDataContext['updateValue']} */
    const updateValue = useCallback((key, value) => {
        setData((current) => {
            const currentValue = _.get(current, key);
            // Skip updating data if no data actually changes.
            // or if value is undefined. Overwrite only with null
            if (value === undefined || currentValue === value) {
                return current;
            }
            return _set(key, value, current);
        });
    }, [setData]);

    /** @type {import('components/Form/form').TItemDataContext['clear']} */
    const clear = useCallback((keepInitial) => {
        const newData = keepInitial && initialData ? initialData : Object();
        setData(newData);
        onChange?.(newData);
    }, [initialData, onChange]);

    const value = useMemo(() => ({
        isSaving, isLoading, isSynced, initialValues, data, save, sync, load, updateValue, clear, initialData,
    }), [isSaving, isLoading, isSynced, initialValues, data, save, sync, load, updateValue, clear, initialData]);

    /**
     * Setting and upkeeping initial data.
     * To prevent updates, ensure the identity of initialData does not change.
     *
     * Includes cloning of initialData fields to avoid item mutation problems
     */
    useEffect(() => {
        _.forEach(initialData, (v, key) => updateValue(key, _.cloneDeep(v)));
    }, [initialData, updateValue]);

    useEffect(() => {
        onChange?.(data);
    }, [data, onChange]);

    useEffect(() => {
        if (parentItemData) {
            updateValue('parentItemData', parentItemData);
        }
    }, [parentItemData, setData, updateValue]);

    return (
        <ItemDataContext.Provider value={value}>
            {!notFound && children}
            {notFound && <NotFound showImage={false} />}
        </ItemDataContext.Provider>
    );
}

export {
    ItemData, ItemDataContext,
};
