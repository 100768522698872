import {useCallback, useRef, useState} from 'react';
// PACKAGES
import {generateClient} from 'aws-amplify/api';
import {fetchAuthSession} from 'aws-amplify/auth';
// import {graphqlOperation} from '@aws-amplify/api-graphql';
import _ from 'lodash';
// HOOKS
import {CancelException, useCancellablePromise} from 'hooks/useCancellablePromise';
import {useAuthenticator} from '@aws-amplify/ui-react';

const delay = (ms) => new Promise((resolve) => { setTimeout(resolve, ms); });
const client = generateClient();

/**
 * ## Connection Closed Exception
 *
 * Indicates that a connection has been closed
 */
class ConnectionClosedException extends Error {
    constructor(message = 'Connection closed', options = {}) {
        super(message, options);
        this.name = 'ConnectionClosedException';
    }
}

/**
 * Executes a provided asynchronous function with a retry mechanism.
 * Retries the function a specified number of times in case of a `ConnectionClosedException`,
 * waiting for a specified delay between each retry.
 * @async
 * @function executeWithRetry
 * @param {Function} fn - The asynchronous function to execute. It should return a Promise.
 * @param {number} retries - The number of times to retry the function if it fails with a `ConnectionClosedException`.
 * @param {number} fnDelay - The delay in milliseconds between each retry attempt.
 * @returns {Promise<any>} - The result of the asynchronous function if successful.
 * @throws {Error} - Throws the error if all retry attempts are exhausted or if the error is not a `ConnectionClosedException`.
 */
export const executeWithRetry = async (fn, retries, fnDelay) => {
    for (let attempt = 0; attempt < retries; attempt += 1) {
        try {
            return fn();
        } catch (error) {
            if (error instanceof ConnectionClosedException) {
                if (attempt < retries - 1) {
                    // Wait before retrying
                    // eslint-disable-next-line no-await-in-loop
                    await new Promise((resolve) => { setTimeout(resolve, fnDelay); });
                } else {
                    throw error; // Exhausted retries, throw the error
                }
            } else {
                throw error; // Non-retriable error, throw immediately
            }
        }
    }
    return fn();
};

const AWSAppSyncProvider = () => {
    const {cancellablePromise} = useCancellablePromise();
    const {user} = useAuthenticator();

    const [blockTime, setBlockTime] = useState(0);
    const callTimestamps = useRef([]);

    const call = useCallback(
        async (query, variables) => {
            const currentTime = Date.now();

            // Remove outdated timestamps (older than 1 second)
            callTimestamps.current = callTimestamps.current.filter((timestamp) => currentTime - timestamp < 1000);

            // Add the current timestamp
            callTimestamps.current.push(currentTime);

            // Check if we have exceeded the call limit
            if (callTimestamps.current.length > 3) {
                const newBlockTime = Math.min(16000, blockTime * 2 || 2000); // exponential backoff, max of 16 seconds
                setBlockTime(newBlockTime);
                // eslint-disable-next-line no-console
                console.warn(`Rate limit exceeded. Blocking for ${newBlockTime}ms`);

                await delay(newBlockTime);

                // Reset timestamps after blocking
                callTimestamps.current = [];
                setBlockTime(0);
            }

            const effectiveVariables = variables?.input
                ? {
                    ...variables,
                    ...variables.input,

                }
                : {
                    ...variables,
                };
            let authToken;
            if (user) {
                try {
                    const session = await fetchAuthSession();
                    authToken = session?.tokens?.idToken.toString();
                } catch (error) {
                    // Throw to indicate a failure
                    // Disguised as a cancel error, to keep rest of app behavior consistent
                    throw new CancelException('Canceled due to invalid auth status', {cause: error});
                }
            }

            // const client = generateClient(idToken ? {
            //     headers: {
            //         Authorization: `Bearer ${idToken}`, // add Authentication when available
            //     },
            // } : {});

            // const graphQlProcess = client.graphql({query, variables: effectiveVariables});
            const graphQlProcess = client.graphql({query, variables: effectiveVariables, authToken});
            if (graphQlProcess instanceof Promise) {
                const cPromise = cancellablePromise(
                    graphQlProcess,
                    (p) => client.cancel(p, 'canceled request'),
                );
                return cPromise.catch((error) => {
                    // If the error is because the request was cancelled we can confirm here.
                    if (client.isCancelError(error)) {
                        // console.log(error.message); // "my message for cancellation"
                        // handle user cancellation logic
                        throw new CancelException('Canceled via API');
                    }
                    if (_.some(error.errors, {message: 'Connection has been closed.'})) {
                        throw new ConnectionClosedException('Connection has been closed.');
                    }
                    throw error;
                });
            }
            return graphQlProcess;
        },
        [user, blockTime, cancellablePromise],
    );

    /** @type {(query: string, variables: Record<string, any>) => Promise<any[]>}  */
    const listItems = useCallback(
        (query, variables) => call(query, variables).then((result) => _.get(result.data[Object.keys(result.data)[0]], 'items', result.data[Object.keys(result.data)[0]])),
        [call],
    );
    const getItem = useCallback(async (query, variables) => call(query, variables).then((result) => result.data[Object.keys(result.data)[0]]), [call]);
    const editItem = useCallback(async (query, variables) => call(query, variables).then((result) => result.data[Object.keys(result.data)[0]]), [call]);

    return ({
        call,
        listItems,
        getItem,
        editItem,
    });
};

export {AWSAppSyncProvider, ConnectionClosedException};
