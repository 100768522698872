import {baseLinkSchemaOptions} from 'applications/baseLinkSchemaOptions';

/**
 * @typedef {import('./types').PermissionGroupBeyondBuddy} PermissionGroupBeyondBuddy
 * @typedef {import('beyond-types').OperationName} OperationName
 * @typedef {import('beyond-types').UnmanagedObjectBeyondBuddy} UnmanagedObjectBeyondBuddy
 * @typedef {OperationName | UnmanagedOperation | 'member_of' | "access" | 'readPermission' | 'updatePermission' | 'readAttributes' | 'updateAttributes'} Permission
 * @typedef {import('components/Form/FormElements/formElements').FormElementEntityChooserDataSchemaOptions} FormElementEntityChooserDataSchemaOptions
 * @typedef {import('beyond-types').ManagedObject} ManagedObject
 */
// eslint-disable-next-line
/** @typedef {`${OperationName}${UnmanagedObjectBeyondBuddy}`} UnmanagedOperation */

/** @type {Record<PermissionGroupBeyondBuddy, Permission[]>} */
const permissionGroupDefinition = {
    updateGroup: ['update', 'delete'],
    permissionGroup: ['readPermission', 'updatePermission'],
    attributeGroup: ['readAttributes', 'updateAttributes'],
};

/** @type {(isIncoming: boolean)=>Partial<Record<ManagedObject, FormElementEntityChooserDataSchemaOptions>>} */
const defaultLinkSchemaOptions = (isIncoming) => ({
    User: {
        getFilterMap: (search) => ({search, active: true, ...(!isIncoming ? {permittableOnly: true} : {})}),
        // explicit false check because new options are always active, and the field is not loaded
        isDisabled: (item) => (item?.active === false || (!isIncoming && !item?.grants?.permissionsUpdatable)),
    },
    Group: baseLinkSchemaOptions(isIncoming),
    OrganizationalUnit: baseLinkSchemaOptions(isIncoming),
    Customer: baseLinkSchemaOptions(isIncoming),
});

// /** @type {Record<import('./types').ManagedObjectBeyondBuddy, string>} */
// const entityLabels = {
//     Tenant: 'Mandant',
//     User: 'Benutzer',
//     Group: 'Gruppen',
//     OrganizationalUnit: 'Organisationseinheiten',
//     Customer: 'Kunde',
//     EvacuationPoint: 'Evakuierungspunkt',
// };

/** @type {import('beyond-types').ManagedObject[]} */
// @ts-ignore
const managedEntityKeys = ['Tenant', 'User', 'Group', 'OrganizationalUnit', 'Customer'];

/** @type {import('beyond-types').ManagedObject[]} */
const organizationEntityKeys = ['Tenant', 'User', 'Group', 'OrganizationalUnit'];

/** @type {Record<Permission|PermissionGroupBeyondBuddy, string>} */
const permissionLabels = {
    access: 'Zugriff',
    create: 'Anlegen',
    read: 'Lesen',
    update: 'Bearbeiten',
    updateGroup: 'Schreiben',
    delete: 'Löschen',
    member_of: 'Mitglied',
    permissionGroup: 'Berechtigen',
    readPermission: 'Berechtigungen lesen',
    updatePermission: 'Berechtigungen schreiben',
    attributeGroup: 'Verknüpfungen',
    readAttributes: 'Verknüpfungen lesen',
    updateAttributes: 'Verknüpfungen schreiben',
    createNotification: 'Benachrichtigung erstellen',
    deleteNotification: 'Benachrichtigung löschen',
    updateNotification: 'Benachrichtigung bearbeiten',
    readNotification: 'Benachrichtigung lesen',
    createEvacuation: 'Evakuierung starten',
    deleteEvacuation: 'Evakuierung löschen',
    readEvacuation: 'Evakuierung einsehen',
    updateEvacuation: 'Evakuierung bearbeiten',
    readProtocolEntry: 'Protokolleintrag lesen',
    createProtocolEntry: 'Protokolleintrag erstellen',
    updateProtocolEntry: 'Protokolleintrag bearbeiten',
    deleteProtocolEntry: 'Protokolleintrag löschen',
};

export {
    permissionGroupDefinition as permissionGroupDefinitionBeyondBuddy,
    // entityLabels as entityLabelsBeyondBuddy,
    permissionLabels as permissionLabelsBeyondBuddy,
    managedEntityKeys as managedEntityKeysBeyondBuddy,
    organizationEntityKeys,
    defaultLinkSchemaOptions as defaultLinkSchemaOptionsBeyondBuddy,
};
