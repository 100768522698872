import {Outlet} from 'react-router-dom';
// COMPONENTS & ASSETS
import backgroundSrc from 'assets/images/spl_scr_sun_road.jpg';
// STYLING
import classes from 'assets/theme/layout/SplashScreen/splashScreen.module.scss';
import {Box} from '@mui/material';

/**
 * The wrapper for the SplashScreen component.
 * @returns {import('react').ReactElement} The SplashScreen component.
 */
function SplashScreen() {
    return (
        <Box className={classes.splashContainer}>
            <Box className={classes.leftImageContainer} data-test="SplashScreen_Info_Container">
                <Box component="img" src={backgroundSrc} alt="BEYOND IT SOLUTIONS" />
            </Box>
            <Box className={classes.rightInfoContainer} data-test="SplashScreen_Form_Container">
                <Outlet />
            </Box>
        </Box>
    );
}

export {SplashScreen};
