import {useFindRoute} from 'hooks/useFindRoute';
import {getRoutePath} from 'helper/routes';

import {MenuLink} from 'components/MenuLink';
import {Box} from '@mui/material';
import _ from 'lodash';

/**
 * @typedef {object} RouteLinkProps - properties passed to the RouteLink component
 * @property {string} routeId - the unique identifier of the target route
 * @property {string} [hash] - optional hash to add to the route
 * @property {object} [routeParams] - the parameter to be set in the dynamic path
 * @property {import('react').ReactNode} children All form elements to be rendered and handled
 */

/**
 * .Components
 * @param {RouteLinkProps & Partial<import('react-router-dom').LinkProps & {disabled?: boolean, dataTest?: string}>} props - properties passed to the RouteLink component
 * @returns {import('react').ReactElement} - The public API for rendering a history-aware <a>
 */
export function RouteLink({
    routeId, hash, routeParams, children, dataTest, ...rest
}) {
    const findRoute = useFindRoute();
    let to = getRoutePath(findRoute(routeId), routeParams);

    if (!to) {
        return <Box style={{display: 'none'}} />;
    }

    if (hash) {
        to += (_.startsWith(hash, '#') ? hash : `#${hash}`);
    }

    return (
        <Box data-test={dataTest} style={{display: 'flex', alignItems: 'center'}}>
            <MenuLink to={`/${to}`} {...rest}>{children}</MenuLink>
        </Box>
    );
}
